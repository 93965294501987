import React from 'react'
import tw, { styled } from 'twin.macro'
import Layout from '../../containers/Layout/Layout'
import Seo from '../../components/Seo'
import { Link } from 'gatsby'
import PageCover from '../../components/PageCover'
import BreadCrumbArrow from '../../assets/svgs/breadcrumb-arrow.svg'
import EquipmentCoverImage from '../../assets/images/bg-equipment-maintenance.jpg'
import EquipmentVisualImage from '../../assets/images/equipment-visual.jpg'
import Dots from '../../assets/images/service-dots.png'

const Intro = tw.section`max-w-screen-xl mx-auto px-5 py-20 sm:px-10 lg:px-14 xl:px-28 lg:pt-24 lg:pb-32 flex items-start justify-between`;
const IntroContent = tw.section`md:w-6/12 lg:w-service-item-content`;

const IntroContentHeading = styled.h2`
  ${tw`font-mulish font-extrabold text-brand-dark-200 mb-8 lg:mb-12 text-7/2 md:text-9/2 leading-tight tracking-brand`}
  margin-bottom: ${props => props.marginBottom};
`

const Text = styled.p`
  ${tw`font-lato text-brand-dark-200 text-xl md:text-2xl leading-normal`};
  margin-bottom: ${props => props.marginBottom};
`

const IntroVisuals = styled.section`
  ${tw`hidden md:flex items-start justify-end md:w-5/12 xl:w-service-item-visual relative`}
`
const IntroImageTag = tw.img``;

const DotsImageTag = styled.img`
  ${tw`absolute`};
  z-index: -1;

  @media only screen and (min-width: 768px) {
    bottom: -30px;
    left: -38px;
  }
`;

const ListText = styled.p`
  ${tw`font-lato text-brand-dark-200 text-xl md:text-2xl leading-normal`};
  margin-top: 50px;
  margin-bottom: 21px;
`
const List = styled.ul`
  ${tw`font-lato text-brand-dark-200 text-xl md:text-2xl leading-normal`};
  list-style: disc;
  margin-left: 20px;
`

const FootNoteBg = tw.div`grid gap-x-3 overflow-hidden grid-cols-4`

const FootNoteImage = styled.img`
  ${tw`w-full h-full object-cover relative`};
`;

const cache = {};

function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}

importAll(require.context("../../assets/images/equipment-maintenance-footnote/", false, /\.(png|jpe?g|svg)$/));

const images = Object.entries(cache).map(module => module[1].default);


const EquipmentMaintenance = () => {
  return (
    <Layout>
        <Seo title="Service | Equipment Maintenance"/>

        <PageCover
          imageSrc={EquipmentCoverImage}
          coverTitle="Equipment Maintenance"
        >
          <Link to="/">Home</Link>
          <div>
            <BreadCrumbArrow />
          </div>
          <Link to="/services">Our Services</Link>
          <div>
            <BreadCrumbArrow />
          </div>
          <Link to="/services/equipment-maintenance">Equipment Maintenance</Link>
        </PageCover>
        
        <Intro>
          <IntroContent>
            <IntroContentHeading>
              Equipment Maintenance
            </IntroContentHeading>
            <Text>
              We have built a process to keep your business's equipment in reliable working order.  Whether it be mechanical assets, tools, heavy off-road vehicles, and computer systems, we can provide routine upkeep as well as corrective repair work. 
            </Text>
            <ListText>
              These services include
            </ListText>
            <List>
              <li>Rotating & Static Equipment Maintenance</li>
              <li>Crane & davit services</li>
              <li>Lifting Equipment</li>
              <li>Electrical Maintenance</li>
              <li>Valve Repairs, Servicing & Installation</li>
            </List>
          </IntroContent>
          <IntroVisuals>
            <IntroImageTag src={EquipmentVisualImage} alt="Equipment Maintenance" />
            <DotsImageTag src={Dots} alt="Dots background" />
          </IntroVisuals>
        </Intro>

        

        <FootNoteBg>
        {images.map(image => (
            <FootNoteImage src={image} />
            ))}
        </FootNoteBg>
    
        </Layout>
  )
}

export default EquipmentMaintenance